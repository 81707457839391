import React, { useEffect, useState } from 'react'
import Footer from '../Footer/Footer'
import { useParams } from "react-router-dom";
import Menu from '../Menu/Menu';
import Global from '../Global/Global';
import Loadingstatic from '../Global/Loadingstatic';
import { useLocation } from 'react-router-dom';
import Shortbanner from '../Sections/Shortbanner';
import Parisos from '../Sections/Parisos';
import Marqueeslider from '../Sections/Marqueeslider';
import Sidepointersection from '../Sections/Sidepointersection';

export default function Event() {
    const location = useLocation(); // Get current page URL
    const { slug } = useParams();
    const {callApi, addLessFiles, addScript, urls, empty} = Global();
    const [loading, setLoading] = useState(true)

    const [policy, setPolicy] = useState(null)
    const [related, setRelated] = useState([])
    // const [formData, setFormData] = useState({
    //     name: '',
    //     email: '',
    //     contact_no: '',
    //     msg: '',
    //     required: 'name,contact_no',
    //     tag: 'Service',
    //     pageurl: window.location.href,
    //     service_title: ''
    // });
    const [formData, setFormData] = useState({
        name: '',
        required: 'feedback',
        tag: 'Policy-Feedback',
        pageurl: window.location.href,
        policy_from: '',
        reform: '',
        feedback: ''
    });
    
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const resp = await callApi(formData, "formSubmitReact", "POST", true)
        if(resp.status !== undefined && resp.status === "OK"){
            window.callAlert(resp.message, "successAlert")
            window.location.href="/policy/"+resp.enqid
        }
        
    };

    const getBlog = async () => {

        const resp = await callApi({slug: slug}, "getServiceReact", "POST")
        if(resp.status !== undefined && resp.status === "OK"){
            setFormData((prevData) => ({
                ...prevData,
                ['policy_from']: resp.service.info.title,
            }));

            // resp.service.info.bgImgold = resp.service.info.bgImgold
            // console.log(resp.service)
            setPolicy(resp.service)
            // setRelated(resp.related)
            setLoading(false)
        }
        
    }

    useEffect(() => {
        getBlog()
        
        const less = addLessFiles( urls.assets + "assets/less/sos-pari.less");
        const script = addScript( urls.assets + 'assets/common/sos-pari.js');

        return () => {
            document.head.removeChild(less);
            document.body.removeChild(script);
        };
    }, [])
  return (
    <>
        <Menu />
        {
            loading &&
            <div style={{padding:"100px 0"}} >
                <Loadingstatic />
            </div>
        }
        {
            !loading && !empty(policy) &&
            <>
                <div className='p_25_s196' >
                    <Shortbanner data={policy.info} list ={{}} id={1} />
                </div>
                <div className='p_25_s239'>
                    <Parisos data={policy.info} list ={{}} id={2} />
                </div>
                <div className='p_25_s199'>
                    <Marqueeslider data={policy.info} list ={{}} id={3} />
                </div>
                {
                    [...Array(15)].map((item, index) => {
                        const col = index + 1
                        return(
                            !empty(policy.info['leftRightHeading'+col]) &&
                            <div className='p_25_s225' key={"s" + index}>
                                <Sidepointersection data={
                                    {
                                        secType: policy.info['leftRight'+col] + " Text",
                                        aboutimageold: policy.info['leftRightImg'+col+'old'],
                                        imagealt: policy.info['leftRightImgAlt'+col],
                                        aboutheading: policy.info['leftRightHeading'+col],
                                        aboutpara: policy.info['leftRightPara'+col]
                                    }
                                } 
                                list ={{}} id={4} />
                            </div>
                        )
                    })
                }
            </>
        }
        
        
        <Footer />
    </>
  )
}
