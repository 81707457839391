import React, { useEffect, useState } from 'react'
import Global from '../Global/Global';

export default function Policydocumentsection({ data, list, id, type = "Policy" }) {
  const { urls, callApi } = Global();
  const [services, setServices] = useState([])
  const [loading, setLoading] = useState(false)
  const slug = type == "Event" ? "events" : "policies"
  const getServices = async () => {
    if (!loading) {
      setLoading(true)
      const resp = await callApi({ type: type }, "getServicesReact", "POST", true)
      if (resp.status !== undefined && resp.status === "OK") {
        setServices(resp.services)
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    getServices()
  }, [])

  return (
    <>
      {/* Document Section */}
      <div className="docMain">
        <div className="width90 maxWidth700 floatCenter textCenter">
          <span className="line"></span>
          <p className="abtHead">{data.smallHead}</p>
          <p className="abtSubHead">{data.mainHeading}</p>
          <p className="abtPara">{data.subPara}</p>
        </div>

        <div className="width90 maxWidth1300 floatCenter">
          <div className="grid grid3 gap30">

            {
              services.length > 0 &&
              services.map((item, index) => {
                return (
                  <div key={index} className="docCard">

                    <div className="btnSec grid gap20">
                      <a
                        href={'/'+slug+'/' + item['pageid']}
                        title="View Policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="cardImg">
                          <img
                            className="width100"
                            src={urls.assets + item['info']['card_imageold']}
                            alt={item['info']['title']}
                          />
                        </div>
                        <div className="cardInnerSec">
                          <p className="cardHead line2">{item['info']['title']}</p>
                          <p className="cardPara line3">{item['info']['card_description']}</p>
                          <button className="view">View {item.type}</button>
                        </div>
                      </a>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>

    </>
  )
}
