import React from 'react'
import Global from '../Global/Global';
import { useParams } from 'react-router-dom';

export default function Successpage({data, list, id}) {
    const {urls} = Global();
    const { enqid, donationid } = useParams();
    data.subPara = data.subPara.replace("{enqid}", enqid)
    data.subPara = data.subPara.replace("{donationid}", donationid)
    
  return (
    
    <div
      className="successMain"
      style={{ backgroundImage: 'url(' + urls.assets + data.bgImgold + ')' }}
    >
      <div className="width90 floatCenter">
        <div className="successCard flex alignCenter justifyCenter flexColumn textCenter">
          <div className="svgSec">
            <img className="width100" src={urls.assets + data.iconSecold} alt={data.heading} />
          </div>
          <p className="heading">{data.heading}</p>
          <p>{data.subPara}</p>
        </div>
        <div className='center-text'>
          {
            data.heading === "Thank You for Your Feedback!" ?
            
            <a href={"https://pariindia.in/policies/mission-2029"} style={{display:"inline-block", backgroundColor:"var(--themeColor)", padding:"10px 20px", borderRadius:"5px", color:"#FFFFFF"}}>
              Back
            </a>
:

            <a href={"/"} style={{display:"inline-block", backgroundColor:"var(--themeColor)", padding:"10px 20px", borderRadius:"5px", color:"#FFFFFF"}}>
              Back to Home
            </a>
          }
        </div>
      </div>
    </div>
  )
}
