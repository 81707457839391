import React from 'react'
import Global from '../Global/Global';

export default function Shortbanner({data}) {
console.log(data)
    const {urls} = Global();
  return (
    
    <div 
        className="shortBanMain" 
        style={{ backgroundImage: `url(${ urls.assets + data.bgImgold})` }}
    >
        <div className="width90 maxWidth600 floatCenter textCenter">
            <p className="heading">{data.mainHeading}</p>
            <p className="linksPara">
                <a href="/">Home</a>
                <span>
                    <i className="fa-solid fa-angle-right"></i>
                </span>
                <a href={data.pageLink} title={data.pageName}>{data.pageName}</a>
            </p>
        </div>
    </div>
  )
}
