import React, { useEffect, useState } from 'react'
import Global from '../Global/Global';
import "./Assets/Fancycss.css";
import { useLocation } from 'react-router-dom';
import { Fancybox } from "@fancyapps/ui";
import Loadingstatic from '../Global/Loadingstatic';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
export default function Gallerysection({data, list, id}) {
    const {urls, callApi, empty} = Global();
    const [gallery, setGallery] = useState([])
    // const [pid, setPid] = useState(0)
    const query = useQuery();
    const pid = empty(query.get('pid')) ? 0 : query.get('pid');
    const [loading, setLoading] = useState(true)
    const getGallery = async (pid) => {
        const resp = await callApi({pid: pid}, "getGalleryReact", "POST")
        if(resp.status !== undefined && resp.status === "OK"){
          setGallery(resp.list)
            setLoading(false)
        }
    }
    
    const changePage = (page) => {
      getGallery(page)
    }
    
    useEffect(() => {
        getGallery(pid)
    }, [])
      
  return (
    <div className="width100 galleryMain">
      <div className="width90 floatCenter textCenter" style={{ maxWidth: "900px" }}>
        <p className="smallText">{data.carGalSmallHeading}</p>
        <p className="bannerHeading">{data.carGalMainHeading}</p>
        <p className="tabSubPara" style={{ marginBottom: "50px" }}>
          {data.carGalSubPara}
        </p>
      </div>
      {
        loading &&
        
        <div style={{height:"100px"}} >
            <Loadingstatic height={"100px"} />
        </div>
      }
      <div className="width100 maxWidth1500 floatCenter relative">
                <div className="width90 maxWidth1300 floatCenter">
                    <div className="grid grid3 gap30">
                        {gallery.map((item) => (
                            <React.Fragment key={item.id}>
                                {item.type === "Folder" && (
                                    <div className="galleryCard">
                                        <div className="galleryCardImg">
                                            <a href={`?pid=${item.id}`} title={item.name}>
                                                <img className="width100" src={ urls.assets + item.src} alt={item.name} />
                                            </a>
                                        </div>
                                        <a href={`?pid=${item.id}`} title={item.name}>
                                            <p className="galleryHeading textCenter">{item.name}</p>
                                        </a>
                                    </div>
                                )}

                                {(item.type === "Image" || item.type === "Video") && (
                                    <div className="galleryCard relative">
                                        <div className="galleryCardImg">
                                            <a
                                                href="javascript:void(0)"
                                                data-fancybox="gallery"
                                                data-src={item.type === "Video" ?  urls.assets + item.video :  urls.assets + item.src}
                                            >
                                                <img className="width100" src={ urls.assets + item.src} alt={item.name} />
                                            </a>
                                        </div>
                                        <p className="galleryHeading textCenter">{item.name}</p>
                                        {item.type === "Video" && (
                                            <div className="absolute galleryPlayBtn">
                                                <i className="fas fa-play"></i>
                                            </div>
                                        )}
                                    </div>
                                )}

                                {item.type === "Youtube" && (
                                    <div className="galleryCard relative">
                                        <div className="galleryCardImg">
                                            <a
                                                href="javascript:void(0)"
                                                data-fancybox="gallery"
                                                data-src={item.youtube}
                                            >
                                                <img className="width100" src={ urls.assets + item.src} alt={item.name} />
                                            </a>
                                        </div>
                                        <p className="galleryHeading textCenter">{item.name}</p>
                                        <div className="absolute galleryPlayBtn">
                                            <i className="fas fa-play"></i>
                                        </div>
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
    </div>
  )
}
