import React from 'react'
import './Loading.css';

export default function Loadingstatic({height}) {
  return (
    <div>
        <div className="newLoading" id="lodingdiv" >
            <div className="fullCon" style={{height: (height)}}>
                <span className="loader"></span>
            </div>
        </div>
    </div>
  )
}
