import React, { useEffect, useState } from 'react'
import Global from '../Global/Global';
import Menu from '../Menu/Menu';
import Footer from '../Footer/Footer';
import Policydocumentsection from '../Sections/Policydocumentsection';


export default function Events() {
    const { urls, callApi, addLessFiles, addScript } = Global();
    const [services, setServices] = useState([])
    const [loading, setLoading] = useState(false)

  
    useEffect(() => {

        const less = addLessFiles(urls.assets + "assets/less/policies.less");
        const script = addScript(urls.assets + 'assets/js/pages/policies.js');

        return () => {
            document.head.removeChild(less);
            document.body.removeChild(script);
        };
    }, [])
    return (
        <>
            <Menu />
            <div className='p_29_s211'>
                <Policydocumentsection data={{ smallHead: "Discover Our Events", mainHeading: "Our Events", subPara: "Know what we have been doing" }} list={{}} id={{}} type={"Event"} />
            </div>
            <Footer />
        </>
    )
}
