import React, { useState } from 'react'
import Global from '../Global/Global';

export default function Volunteerform({data, list, id}) {
    const {urls, callApi, empty} = Global();

    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        email: '',
        contact_no: '',
        city: '',
        state: '',
        country: '',
        address: '',
        postal_code: '',
        date: '',
        qualification: '',
        profession: '',
        lang: '',
        message: '',
        required: 'fname,lname,email,contact_no,city,state,country,address,postal_code,date,qualification,profession,lang',
        tag: 'Become a Volunteer',
        pageurl: window.location.href,
        payment_id: '',
    });



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const donationDone = async (response) => {
        
      window.callAlert("Loading Please Wait...", "infoAlert", 1000000);

      var payid = response.razorpay_payment_id
      var temp = formData;
      temp['payment_id'] = payid;

      const resp = await callApi(temp, "formSubmitReact", "POST", true)
      if(resp.status !== undefined && resp.status === "OK"){
          window.callAlert(resp.message, "successAlert")
          window.location.href="/volunteerenq/" + resp.enqid
      }
  }

  const callRazorPay = (amount) => {
      window.callAlert("Loading Please Wait...", "infoAlert", 1000000);
      const options = {
          key: "rzp_live_MBhGSK6P04PORE", // Replace with your Razorpay Key
          amount: amount * 100, // Amount in paisa (e.g., ₹500 = 50000)
          currency: "INR",
          name: "Pari Foundation",
          description: 'Become a Volunteer',
          handler: function (response) {
              donationDone(response)
          //   console.log(response);
            // Handle success here
          },
          theme: {
            color: "#3399cc",
          },
        };
        
        const razorpay = new window.Razorpay(options);
        razorpay.open();
          
        // Attach a payment failure handler
          razorpay.on("payment.failed", function (response) {
              window.$(".alerts").fadeOut();
          });
          razorpay.on("payment.canceled", function (response) {
              window.$(".alerts").fadeOut();
          });
  }

    const handleSubmit = async (e) => {
        e.preventDefault();
        var req = formData['required'].split(",");
        var err = "";
        window.$(req).each(function(index, item){
          if(empty(formData[item])){
            err += item + ", ";
          }
        });

        if(!empty(err)){
          window.callAlert(err + ' cannot be null!!!', "warningAlert")
        }else{
          callRazorPay(data.amount);
        }

    };
  return (
    
    
    <div className="width100 formMainSec">
    <div className="width90 maxWidth1500 floatCenter formInnerSec">
        <div className="grid grid2 gap50">
            {/* Left Section */}
            <div className="relative">
                <div className="formImg width100">
                    <p className="mainHeading head2">{data.leftHeading}</p>
                    <p className="subPara para2">{data.leftPara}</p>
                    <img
                        className="width100"
                        src={urls.assets + data.leftImgold}
                        alt={data.imgAlt}
                    />
                </div>
            </div>

            {/* Form Section */}
            <div className="formInnerHome">
                
    <form onSubmit={handleSubmit}>
      <input type="hidden" name="required" value="name,contact_no" />
      <input type="hidden" name="tag" value="Volunteer" />
      <input type="hidden" name="pageurl" value={window.location.href} />

      <div className="grid grid2 gap20 nameGrid">
        <div className="fNameSec">
          <div className="labelSec">
            <label>
              First Name: <span>*</span>
            </label>
          </div>
          <div className="inputName marBot20">
            <input
              type="text"
              name="fname"
              required
              placeholder="First Name"
              value={formData.fname}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="lNameSec">
          <div className="labelSec">
            <label>
              Last Name: <span>*</span>
            </label>
          </div>
          <div className="inputName marBot20">
            <input
              type="text"
              name="lname"
              required
              placeholder="Last Name"
              value={formData.lname}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div className="grid grid2 gap20 nameGrid">
        <div className="emailSec">
          <div className="labelSec">
            <label>
              Your Email: <span>*</span>
            </label>
          </div>
          <div className="inputName marBot20">
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="phnSec">
          <div className="labelSec">
            <label>
              Your Phone Number: <span>*</span>
            </label>
          </div>
          <div className="inputName marBot20">
            <input
              type="number"
              name="contact_no"
              required
              placeholder="Your Phone Number"
              value={formData.contact_no}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div className="grid grid2 gap20 nameGrid">
        <div className="fNameSec">
          <div className="labelSec">
            <label>
              City: <span>*</span>
            </label>
          </div>
          <div className="inputName marBot20">
            <input
              type="text"
              name="city"
              required
              placeholder="City"
              value={formData.city}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="lNameSec">
          <div className="labelSec">
            <label>
              State: <span>*</span>
            </label>
          </div>
          <div className="inputName marBot20">
            <input
              type="text"
              name="state"
              required
              placeholder="State"
              value={formData.state}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div className="grid grid2 gap20 nameGrid">
        <div className="lNameSec">
          <div className="labelSec">
            <label>
              Country: <span>*</span>
            </label>
          </div>
          <div className="inputName marBot20">
            <input
              type="text"
              name="country"
              required
              placeholder="Country"
              value={formData.country}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="addressSec">
          <div className="labelSec">
            <label>
              Full Address: <span>*</span>
            </label>
          </div>
          <div className="inputName marBot20">
            <input
              type="text"
              name="address"
              placeholder="Address"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div className="lNameSec">
        <div className="labelSec">
          <label>
            Postal Code: <span>*</span>
          </label>
        </div>
        <div className="inputName marBot20">
          <input
            type="text"
            name="postal_code"
            required
            placeholder="Postal Code"
            value={formData.postal_code}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="dateSec">
        <div className="labelSec">
          <label>
            Date of Birth: <span>*</span>
          </label>
        </div>
        <div className="inputName marBot20">
          <input
            type="date"
            name="date"
            placeholder="DOB"
            value={formData.date}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="dateSec">
        <div className="labelSec">
          <label>
            Qualification: <span>*</span>
          </label>
        </div>
        <div className="inputName marBot20">
          <input
            type="text"
            name="qualification"
            placeholder="Qualification"
            value={formData.qualification}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="dateSec">
        <div className="labelSec">
          <label>
            Profession: <span>*</span>
          </label>
        </div>
        <div className="inputName marBot20">
          <input
            type="text"
            name="profession"
            placeholder="Profession"
            value={formData.profession}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="selectSecMain">
        <div className="labelSec">
          <label>
            Area of Interest: <span>*</span>
          </label>
        </div>
        <div className="selectMain marBot20">
          <select
            className="selectSec"
            name="selectArea"
            value={formData.selectArea}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              -- Area of Interest --
            </option>
            {Array.from({ length: 12 }, (_, i) => i + 1).map((index) =>
              data[`field${index}`] ? (
                <option key={index} value={data[`field${index}`]}>
                  {data[`field${index}`]}
                </option>
              ) : null
            )}
          </select>
        </div>
      </div>

      <div className="selectSecMain">
        <div className="labelSec">
          <label>
            Time You Can Spend: <span>*</span>
          </label>
        </div>
        <div className="selectMain marBot20">
          <select
            className="selectSec"
            name="selectTime"
            value={formData.selectTime}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              -- Time You Can Spend --
            </option>
            {Array.from({ length: 12 }, (_, i) => i + 1).map((index) =>
              data[`timeField${index}`] ? (
                <option key={index} value={data[`timeField${index}`]}>
                  {data[`timeField${index}`]}
                </option>
              ) : null
            )}
          </select>
        </div>
      </div>

      <div className="dateSec">
        <div className="labelSec">
          <label>
            Language Known: <span>*</span>
          </label>
        </div>
        <div className="inputName marBot20">
          <input
            type="text"
            name="lang"
            placeholder="Language Known"
            value={formData.lang}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="textSecMain">
        <div className="labelSec">
          <label>Message:</label>
        </div>
        <div className="textSec marBot20">
          <textarea
            rows="5"
            placeholder="Any special request"
            name="message"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
        </div>
      </div>

      <div className="joinBtnSec">
        <button type="submit" className="joinBtn">
          Submit
        </button>
      </div>
    </form>
            </div>
        </div>
    </div>
</div>
  )
}
