import React, { useEffect, useState } from 'react'
import Footer from '../Footer/Footer'
import { useParams } from "react-router-dom";
import Menu from '../Menu/Menu';
import Global from '../Global/Global';
import Loadingstatic from '../Global/Loadingstatic';
import { useLocation } from 'react-router-dom';

export default function Policysubmission() {
    const location = useLocation(); // Get current page URL
    const { slug } = useParams();
    const {callApi, addCss, addScript, urls, empty} = Global();
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState(null)

    
    const getFeedbacks = async (page) => {

        const resp = await callApi({slug: slug}, "getFeedbacksReact?page="+page, "POST")
        if(resp.status !== undefined && resp.status === "OK"){
            setList(resp.list)
            setLoading(false)
        }
    }
    const changePage = (page) => {
        getFeedbacks(page)
    }
    useEffect(() => {
        getFeedbacks(1)
    }, [])

  return (
    <>
        <Menu />
        {
            loading &&
            <div style={{padding:"100px 0"}} >
                <Loadingstatic />
            </div>
        }
        {
            !loading && 
            <>
                <section className='pariSubmissions'  >
                    <p class="pariReformHeading center-text">Valuable Feedbacks for महिला/बाल सुरक्षा - 2029</p>
                    <div className='width90 maxWidth1500 grid grid3 gap10 floatCenter'>
                        {
                            !empty(list) && list.data.length > 0 &&
                            list.data.map((item, index) => {
                                const json = !empty(item.json) ? JSON.parse(item.json) : null
                                return(

                                    <div className='pariSubmissionCard relative' key={"key" + index } >
                                        <div className='pariSeq'>
                                            {index + 1}
                                        </div>
                                        <div className="pariLogoArea">
                                            <div className='pariLogo'>
                                                <img src='https://api.pariindia.in/storage/page/contact/1734079841New-Project-(5).jpg' className='cover' alt='Pari Logo' />
                                            </div>
                                            <div className='pariLogoName' > 
                                                {
                                                    !empty(json) &&
                                                    <>
                                                        <p>
                                                            <strong>Name: </strong> {!empty(json.name) ? json.name : 'Anonymous'}
                                                        </p>
                                                        {
                                                            !empty(json.reform) &&
                                                            <p>
                                                                <strong>Reform: </strong> { json.reform }
                                                            </p>
                                                        }
                                                        <p>
                                                            <strong>Feedback: </strong> {json.feedback}
                                                        </p>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    
                {
                <>
                    <div className="blogsV2" style={{marginTop:"20px"}}>
                        <div className="maxWidth1500 float-center center-text navigationArea gap10">
                            {
                            [...Array(list.last_page)].map((item, index) => {
                                return(
                                    <span key={index} className={"navigations " + ((index + 1) == list.current_page ? 'activeNav' : '') } onClick={() => changePage(index + 1)} >
                                        {index + 1}
                                    </span>
                                )
                            })
                            }
                            
                        </div>
                    </div>
                </>
                }
                </section>
            </>
        }
        
        
        <Footer />
    </>
  )
}
